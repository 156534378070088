
// mixin
@mixin button-icon-variant($size) {
  height: $size;
  width: $size;
  line-height: $size;
  i { line-height: $size; }
  &.btn-icon-lined {
    line-height: ($size - 4px);
    i { line-height: ($size - 4px); }

    &.btn-icon-thin {
      line-height: ($size - 2px);
      i { line-height: ($size - 2px); }
    }
  }
}

@mixin social-button-variant($background, $border) {
  color: $white;
  background-color: $background;
  border-color: $border;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
    background-color: darken($background, 8%);
    border-color: darken($border, 12%)
  }
}

// override Bootstrap
button {
  &:focus {
    outline: 0;
  }
}



// make sure it overrides material2 styles
a,
button {
  &.btn-w-xs { min-width: 80px; }
  &.btn-w-sm { min-width: 100px; }
  &.btn-w-md { min-width: 135px; }
  &.btn-w-lg { min-width: 160px; }

  &.btn-round { border-radius: 2em; }
  &.btn-gap { margin: 5px; }
  &.btn-gap-h { margin: 0 5px; }
  &.btn-gap-v { margin: 0 0 5px; }
}


//
.btn-icon {
  padding: 0;
  display: inline-block;
  text-align: center;
  border-radius: $border-radius;
  @include button-icon-variant(35px);
}
.btn-icon-round {
  border-radius: 50%;
}
.btn-icon-sm {
  @include button-icon-variant(30px);
}
.btn-icon-md {
  @include button-icon-variant(45px);
  font-size: 18px;
}
.btn-icon-lg {
  @include button-icon-variant(65px);
  font-size: 28px;
}
.btn-icon-lg-alt {
  @include button-icon-variant(70px);
}
.btn-icon-xl {
  @include button-icon-variant(80px);
}


// social buttons
.btn-twitter { @include social-button-variant(#00c7f7, #00c7f7); }
.btn-facebook { @include social-button-variant(#335397, #335397); }
.btn-gplus,
.btn-google-plus { @include social-button-variant(#dd4a38, #dd4a38); }
.btn-instagram { @include social-button-variant(#82685A, #82685A); }
.btn-vimeo { @include social-button-variant(#63879C, #63879C); }
.btn-flickr { @include social-button-variant(#0061DB, #0061DB); }
.btn-github { @include social-button-variant(#3B3B3B, #3B3B3B); }
.btn-pinterest { @include social-button-variant(#D73532, #D73532); }
.btn-tumblr { @include social-button-variant(#586980, #586980); }
.btn-linkedin { @include social-button-variant(#018FAF, #018FAF); }
.btn-dribbble { @include social-button-variant(#EA73A0, #EA73A0); }
.btn-stumbleupon { @include social-button-variant(#EA4B24, #EA4B24); }
.btn-lastfm { @include social-button-variant(#B80638, #B80638); }
.btn-evernote { @include social-button-variant(#3BAB27, #3BAB27); }
.btn-skype { @include social-button-variant(#00B0F6, #00B0F6); }
.btn-soundcloud { @include social-button-variant(#0066FF, #0066FF); }
.btn-behance { @include social-button-variant(#B80638, #B80638); }
.btn-rss { @include social-button-variant(#F79638, #F79638); }
.btn-youtube { @include social-button-variant(#CC181E, #CC181E); }



// Dark theme
// --------------------------------------------------
.theme-gray,
.theme-dark {
  .btn-default,
  .btn-secondary {
    background-color: rgba(#fff, .05);
    border-color: rgba(#000, .15);
    color: $theme_dark_text_color;
  }
}
